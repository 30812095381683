body,
.root,
.app {
  height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
}
